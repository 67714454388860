import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import useStyles from './styles';

type Props = {
  onChange: () => void;
  text: string;
  selected?: boolean;
};

const SelectItem: FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
> = ({ text, onChange, selected = false }) => {
  const { classes } = useStyles({ selected });

  return (
    <button onClick={onChange} className={classes.item} type="button">
      {text}
    </button>
  );
};

export default SelectItem;
