import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  offset: number;
};

export default makeStyles<Props>()(({ palette, shadows }, { offset }) => ({
  drawerContainer: {
    width: '100%',
    height: 'fit-content',
    maxHeight: rem(Math.min(window.innerHeight - 200, 500)),
    padding: `${rem(12)} ${rem(8)}`,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: rem(offset),
    left: 0,
    boxShadow: shadows[8],
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    // '-ms-overflow-style:': 'none' /* IE and Edge */,
    /** Firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: `${palette.neutral[700]} ${palette.neutral[800]}`,
    /** Chrome related browsers */
    '&::-webkit-scrollbar': {
      width: rem(10),
      height: rem(10),
    },
    '&::-webkit-scrollbar-track': {
      background: palette.common.white,
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette.neutral[700],
      borderRadius: rem(5),
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: palette.neutral[800],
    },
    backgroundColor: palette.common.white,
    zIndex: 100,
    borderRadius: rem(8),
  },
  focus: {
    width: 'fit-content',
    height: 'fit-content',
    backgroundColor: 'transparent',
  },
}));
