import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle1: {
    id: 'Landing.Teacher.Carroussel.card1.title',
    defaultMessage: `Ethan`,
  },
  cardDescription1: {
    id: 'Landing.Teacher.Carroussel.card2.description',
    defaultMessage:
      'Gérer mes cours est hyper simple avec Les Sherpas : je fixe mes tarifs et dispos comme je le souhaite, sans prise de tête. L’équipe est super réactive, c’est très agréable !',
  },
  cardTitle2: {
    id: 'Landing.Teacher.Carroussel.card1.title',
    defaultMessage: `Garance`,
  },
  cardDescription2: {
    id: 'Landing.Teacher.Carroussel.card2.description',
    defaultMessage:
      'Devenir Sherpa a été la découverte de mon année : j’ai décroché un job flexible, qui marche très bien avec mes contraintes de cours, et j’ai rajouté une super expérience sur mon CV !',
  },
  cardTitle3: {
    id: 'Landing.Teacher.Carroussel.card1.title',
    defaultMessage: `Karen`,
  },
  cardDescription3: {
    id: 'Landing.Teacher.Carroussel.card2.description',
    defaultMessage:
      'C’est d’abord la passion de transmettre qui m’a fait devenir Sherpa, mais aussi la vision de l’éducation que je partage totalement : pouvoir offrir des cours de qualité au plus grand nombre, c’est formidable !',
  },
  cardTitle4: {
    id: 'Landing.Teacher.Carroussel.card1.title',
    defaultMessage: `Eva`,
  },
  cardDescription4: {
    id: 'Landing.Teacher.Carroussel.card2.description',
    defaultMessage:
      'J’adore être Sherpa : c’est bien payé ! Je suis indépendante, et c’est très pratique ! ',
  },
  cardTitle5: {
    id: 'Landing.Teacher.Carroussel.card1.title',
    defaultMessage: `Gabriel`,
  },
  cardDescription5: {
    id: 'Landing.Teacher.Carroussel.card2.description',
    defaultMessage:
      'Professeur depuis deux ans chez Les Sherpas, je recommande fortement. Les Sherpas, c’est une équipe dynamique, innovante, cherchant sans cesse à enrichir les services qu’elle propose. L’expérience est au top autant pour les professeurs que pour les élèves. Foncez !',
  },
});
