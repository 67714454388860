import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FocusOn } from 'react-focus-on';

import SelectItem from '@/Molecules/Select/Item/SelectItem';

import useStyles from './style';

type Props = {
  onClose: () => void;
  value: number;
  onChange: (_value: number) => void;
  selectors: string[] | number[];
};

const NO_ADJUSTMENT_OFFSET = 50;

const SelectDrawer: FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
> = ({ className, onClose, selectors, value, onChange }) => {
  const [offset, setOffset] = useState<number>(NO_ADJUSTMENT_OFFSET);
  const ref = useRef(null);

  const { classes, cx } = useStyles({ offset });

  useEffect(() => {
    const current: HTMLElement = ref?.current;
    if (current) {
      const { top, height } = current.getBoundingClientRect();
      const differenceHeight = window.innerHeight - top - height;
      if (differenceHeight - NO_ADJUSTMENT_OFFSET < 0) {
        setOffset(differenceHeight);
      } else {
        setOffset(NO_ADJUSTMENT_OFFSET);
      }
    }
  }, [ref]);

  return (
    <FocusOn
      onClickOutside={onClose}
      onEscapeKey={onClose}
      className={cx(classes.focus, className)}
    >
      <div className={classes.drawerContainer} ref={ref} role="dialog">
        {selectors?.map((element, i: number) => (
          <SelectItem
            key={`select-item-${i}`}
            onChange={() => onChange(i)}
            selected={i === value}
            text={element}
          />
        ))}
      </div>
    </FocusOn>
  );
};

export default SelectDrawer;
