import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  incomeResult: {
    display: 'flex',
    flexFlow: 'column',
    borderRadius: rem(12),
    backgroundColor: palette.neutral.light,
    padding: `${rem(12)} ${rem(16)} ${rem(16)}`,
    gap: rem(12),
    border: `${rem(1)} solid ${palette.neutral[800]}`,
  },
  estimateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipContainer: {
    display: 'flex',
    gap: rem(6),
    alignItems: 'center',
  },
  grey: {
    color: palette.neutral[500],
  },
}));
