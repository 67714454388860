import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Typography } from '@mui/material';
import Image from 'next/image';

import VisioSection from '@/components/Landing/Visio/VisioSection/VisioSection';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

type props = {
  isTeacher?: boolean;
};

const Visio: FC<PropsWithChildren<props>> = ({ isTeacher = false }) => {
  const { classes } = useStyles();
  return (
    <article className={classes.visio}>
      <Typography component="h2" variant="titleLg" className={classes.title}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography component="p" variant="body2xl" className={classes.subtitle}>
        <FormattedMessage
          {...messages.subtitle}
          values={{ enter: EnterChunk, isTeacher }}
        />
      </Typography>
      <Image
        src="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/visio.png"
        width={288}
        height={169}
        sizes="100vw"
        alt="Donner des cours particuliers en visio"
        loader={getImageFromCloudImage}
        className={classes.visioImage}
      />
      <div className={classes.sections}>
        <VisioSection
          title={<FormattedMessage {...messages.sectionOneTitle} />}
          subtitle={<FormattedMessage {...messages.sectionOneSubtitle} />}
        />
        <Divider orientation="vertical" className={classes.divider} />
        <VisioSection
          title={
            <FormattedMessage
              {...messages.sectionTwoTitle}
              values={{ isTeacher }}
            />
          }
          subtitle={
            <FormattedMessage
              {...messages.sectionTwoSubtitle}
              values={{ isTeacher }}
            />
          }
        />

        <Divider orientation="vertical" className={classes.divider} />

        <VisioSection
          title={<FormattedMessage {...messages.sectionThreeTitle} />}
          subtitle={<FormattedMessage {...messages.sectionThreeSubtitle} />}
        />
      </div>
    </article>
  );
};

export default Visio;
