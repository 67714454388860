import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'givecourse.teacher.howItWorks.title',
    defaultMessage: 'Rejoins la communauté des Sherpas !',
  },
  subtitle: {
    id: 'givecourse.teacher.howItWorks.subtitle',
    defaultMessage:
      'Étudiant à l’Université ou en Grande École, professeur de l’Éducation Nationale ou Professionnel, peu importe ton statut, toi aussi tu peux rejoindre Les Sherpas et enseigner à des élèves formidables !',
  },
  firstTitle: {
    id: 'givecourse.teacher.howItWorks.first.title',
    defaultMessage:
      'Partage ton savoir facilement !\nDeviens Sherpa en 10 min.',
  },
  firstSubtitle: {
    id: 'givecourse.teacher.howItWorks.first.subtitle',
    defaultMessage:
      'Chez Les Sherpas, nous sommes là pour t’aider à te concentrer sur ce que tu sais faire le mieux  : partager ton savoir et faire progresser tes élèves.\n\nNous te fournissons tous les outils et conseils pour accompagner tes élèves et faire de l’enseignement une source fun de revenus réguliers !',
  },
  firstCta: {
    id: 'givecourse.teacher.howItWorks.first.cta',
    defaultMessage: 'Je me lance !',
  },
  secondTitle: {
    id: 'givecourse.teacher.howItWorks.second.title',
    defaultMessage: 'Laisse les élèves venir à toi.\nConstruis ta réputation. ',
  },
  secondSubtitle: {
    id: 'givecourse.teacher.howItWorks.second.subtitle',
    defaultMessage:
      'Une fois ton compte créé, et ton profil vérifié, nous te proposerons tes premiers élèves. Nous comptabilisons les cours donnés et les avis reçus pour t’aider à construire ta réputation.\n\nC’est simple : plus tu utilises Les Sherpas, meilleurs sont tes avis, et plus nous te proposerons d’élèves ! ',
  },
  secondCta: {
    id: 'givecourse.teacher.howItWorks.second.cta',
    defaultMessage: 'J’en profite !',
  },
  thirdTitle: {
    id: 'givecourse.teacher.howItWorks.third.title',
    defaultMessage: 'Choisis la liberté & la sérénité avec Les Sherpas. ',
  },
  thirdSubtitle: {
    id: 'givecourse.teacher.howItWorks.third.subtitle',
    defaultMessage: `Avec Les Sherpas, tu t’organises comme tu veux. Tu gères tes tarifs, tes disponibilités, et même le lieu où tu souhaites donner cours. Tu es 100% indépendant !\n\nEnsuite, tout est fait pour que tu aies l’esprit tranquille : notre équipe est là pour t’aider 7j/7, et nous te garantissons le paiement de tes cours sous 10 jours ouvrés.`,
  },
  thirdCta: {
    id: 'givecourse.teacher.howItWorks.third.cta',
    defaultMessage: `Je deviens Sherpa !`,
  },
});
