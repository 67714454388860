import { defineMessages } from 'react-intl';

export default defineMessages({
  estimate: {
    id: 'designSystemLanding.income.simulator.result.estimate',
    defaultMessage: 'Estimation',
  },
  helper: {
    id: 'designSystemLanding.income.simulator.result.helper',
    defaultMessage: 'Indicatif sur une base moyenne de 20€/h',
  },
  price: {
    id: 'designSystemLanding.income.simulator.result.price',
    defaultMessage: '{price} € <grey>/mois</grey>',
  },
  button: {
    id: 'designSystemLanding.income.simulator.result.button',
    defaultMessage: 'Je deviens Sherpa !',
  },
});
