import { FC, PropsWithChildren, ReactNode } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Typography } from '@mui/material';

import useStyles from './styles';

type props = {
  title: ReactNode;
  subtitle: ReactNode;
};

const VisioSection: FC<PropsWithChildren<props>> = ({ title, subtitle }) => {
  const { classes } = useStyles();
  return (
    <div>
      <div className={classes.sectionTitle}>
        <CheckCircleOutlineRoundedIcon className={classes.icon} />
        <Typography component="h3" variant="title2Xs">
          {title}
        </Typography>
      </div>
      <Typography component="p" variant="bodyLg" className={classes.textWhite}>
        {subtitle}
      </Typography>
    </div>
  );
};

export default VisioSection;
