import { defineMessages } from 'react-intl';
import { EducationalStage } from '@les-sherpas/sherpas-toolbox';

export default defineMessages({
  title: {
    id: 'designSystemLanding.income.simulator.title',
    defaultMessage: 'Calcule tes revenus ! 💸',
  },
  level: {
    id: 'designSystemLanding.income.simulator.level',
    defaultMessage: 'À quel niveau souhaites-tu enseigner ?',
  },
  levels: {
    id: 'designSystemLanding.income.simulator.level.array',
    defaultMessage: `{value, select,
                  ${EducationalStage.Primaire} {Primaire}
                  ${EducationalStage.College} {Collège}
                  ${EducationalStage.Lycee} {Lycée}
                  ${EducationalStage.Prepa} {Prépa}
                  ${EducationalStage.Superieur} {Supérieur}
                  other {Supérieur} 
                  }`,
  },
  hour: {
    id: 'designSystemLanding.income.simulator.hour',
    defaultMessage: 'Combien d’heures peux-tu donner par semaine ?',
  },
  selectHour: {
    id: 'designSystemLanding.income.simulator.select.hour',
    defaultMessage: 'Entre {min}h et {max}h de cours <grey>/semaine</grey>',
  },
});
