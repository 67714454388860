import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { text }, breakpoints, shadow }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: rem(288),
    [breakpoints.up('md')]: {
      maxWidth: rem(600),
      height: rem(360),
    },
    [breakpoints.up('lg')]: {
      alignItems: 'start',
      maxWidth: rem(405),
    },
  },
  image: {
    borderRadius: rem(12),
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    minHeight: rem(240),
    boxShadow: shadow.medium,
    [breakpoints.only('md')]: {
      maxWidth: rem(405),
    },
  },
  title: {
    marginTop: rem(24),
    fontSize: rem(20),
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      textAlign: 'start',
      fontSize: rem(24),
      height: rem(72),
    },
  },
  subtitle: {
    marginTop: rem(8),
    color: text.secondary,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      lineHeight: rem(28),
    },
    [breakpoints.up('lg')]: {
      textAlign: 'start',
      fontSize: rem(20),
      lineHeight: rem(32),
    },
  },
}));
