import { FC, HTMLAttributes, PropsWithChildren, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EducationalStage } from '@les-sherpas/sherpas-toolbox';
import { Slider, Typography } from '@mui/material';

import Form from '@/Molecules/Form/Form';
import Select from '@/Molecules/Select/Select';
import IncomeResult from '@Landing/Systems/IncomeSimulator/IncomeResult/IncomeResult';
import {
  getMinAndMaxNbOfHoursFromSlider,
  getPriceByLevelAndHours,
  LEVEL_LIST,
  MARKS_SLIDER,
} from '@Landing/Systems/IncomeSimulator/IncomeSimulator.utils';

import messages from './messages';

import useStyles from './styles';

const IncomeSimulator: FC<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ className }) => {
  const { classes, cx } = useStyles();
  const { formatMessage } = useIntl();
  const [level, setLevel] = useState<EducationalStage>(LEVEL_LIST[2]);
  const [sliderValue, setSliderValue] = useState<number>(1);

  const handleLevelChange = (value) => {
    setLevel(LEVEL_LIST[value]);
  };

  const onSliderValueChange = (_, value: number) => {
    setSliderValue(value);
  };

  return (
    <div className={cx(classes.incomeSimulator, className)}>
      <Typography variant="titleXs" component="h2" className={classes.title}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Form
        className={classes.selectorLevel}
        variant="bodyLgBold"
        htmlFor="select-level"
        label={<FormattedMessage {...messages.level} />}
      >
        <Select
          selectors={LEVEL_LIST.map((level) =>
            formatMessage(messages.levels, {
              value: level,
            })
          )}
          value={LEVEL_LIST.findIndex((selected) => selected === level)}
          onChange={handleLevelChange}
        />
      </Form>
      <div className={classes.selectorHour}>
        <Typography variant="bodyLgBold" component="span" id="hour-slider">
          <FormattedMessage {...messages.hour} />
        </Typography>
        <Slider
          defaultValue={1}
          aria-labelledby="hour-slider"
          valueLabelDisplay="off"
          marks={MARKS_SLIDER}
          step={null}
          min={0}
          max={8}
          value={sliderValue}
          onChange={onSliderValueChange}
          classes={{
            root: classes.slider,
            track: classes.track,
            rail: classes.rail,
            mark: classes.mark,
            thumb: classes.thumb,
          }}
        />
        <Typography variant="title2Xs" component="span">
          <FormattedMessage
            {...messages.selectHour}
            values={{
              ...getMinAndMaxNbOfHoursFromSlider(sliderValue),
              grey: (chunks) => <span className={classes.grey}>{chunks}</span>,
            }}
          />
        </Typography>
      </div>
      <IncomeResult price={getPriceByLevelAndHours(level, sliderValue)} />
    </div>
  );
};

export default IncomeSimulator;
