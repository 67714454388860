import { EducationalStage } from '@les-sherpas/sherpas-toolbox';

export const LEVEL_LIST = [
  EducationalStage.Primaire,
  EducationalStage.College,
  EducationalStage.Lycee,
  EducationalStage.Superieur,
  EducationalStage.Prepa,
];

export const MARKS_SLIDER = [
  {
    value: 1,
  },
  {
    value: 4,
  },
  {
    value: 6,
  },
  {
    value: 8,
  },
];

const MARKS_LABEL = [
  {
    min: 1,
    max: 2,
  },
  {
    min: 2,
    max: 4,
  },
  {
    min: 4,
    max: 6,
  },
  {
    min: 6,
    max: 10,
  },
];

export const getMinAndMaxNbOfHoursFromSlider = (
  currentValue: number
): { min: number; max: number } => {
  const { min, max } =
    MARKS_LABEL[MARKS_SLIDER.findIndex((mark) => mark.value === currentValue)];
  return { min, max };
};

const PRICE_BY_LEVEL = [
  {
    level: EducationalStage.Primaire,
    price: 1600,
  },
  {
    level: EducationalStage.College,
    price: 1800,
  },
  {
    level: EducationalStage.Lycee,
    price: 2000,
  },
  {
    level: EducationalStage.Prepa,
    price: 3000,
  },
  {
    level: EducationalStage.Superieur,
    price: 2500,
  },
];

export const getPriceByLevelAndHours = (
  currentLevel: EducationalStage,
  currentSliderValue: number
): number => {
  const { price } = PRICE_BY_LEVEL.find(({ level }) => level === currentLevel);
  const { max: nbOfHours } =
    getMinAndMaxNbOfHoursFromSlider(currentSliderValue);
  return price * nbOfHours * 4;
};
