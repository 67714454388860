import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  howItWorks: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(60),
    height: rem(2795),
    padding: `${rem(40)} ${rem(16)} ${rem(48)}`,
    alignItems: 'center',
    [breakpoints.up('md')]: {
      padding: `${rem(60)} ${rem(24)} ${rem(48)}`,
      height: rem(3368),
    },
    [breakpoints.up('lg')]: {
      height: rem(1976),
      padding: rem(80),
      maxWidth: rem(1440),
      margin: 'auto',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: rem(16),
    textAlign: 'center',
  },
  subtitle: {
    color: palette.neutral[500],
  },
  imageSection2: {
    [breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: rem(640),
      height: rem(500),
      borderRadius: 0,
    },
  },
}));
