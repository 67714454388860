import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  selected?: boolean;
};

export default makeStyles<Props>()(({ palette }, { selected }) => ({
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: rem(40),
    color: palette.neutral[200],
    cursor: 'pointer',
    border: 'none',
    backgroundColor: selected ? palette.neutral[800] : palette.common.white,
    '&:hover': {
      backgroundColor: !selected && palette.neutral[900],
      transition:
        !selected && `background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    borderRadius: rem(4),
    padding: rem(10),
    textOverflow: 'ellipsis',
  },
}));
