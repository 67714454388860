import { FC, PropsWithChildren, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import Carroussel from '@Landing/Systems/Carroussel/Carroussel';

import messages from './messages';

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
};

const LandingCarroussel: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
}) => (
  <Carroussel title={title} subtitle={subtitle}>
    <Carroussel.QuoteStudent
      description={<FormattedMessage {...messages.cardDescription1} />}
      imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-verbatim-01.jpg"
      rate={5}
      name={<FormattedMessage {...messages.cardTitle1} />}
      alt="Avis de nos sherpas!"
    />
    <Carroussel.QuoteStudent
      description={<FormattedMessage {...messages.cardDescription2} />}
      imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-verbatim-02.jpg"
      alt="Avis de nos sherpas!"
      rate={5}
      name={<FormattedMessage {...messages.cardTitle2} />}
    />
    <Carroussel.QuoteStudent
      description={<FormattedMessage {...messages.cardDescription3} />}
      imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-verbatim-03.jpg"
      alt="Avis de nos sherpas!"
      rate={5}
      name={<FormattedMessage {...messages.cardTitle3} />}
    />
    <Carroussel.QuoteStudent
      description={<FormattedMessage {...messages.cardDescription4} />}
      imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-verbatim-04.jpg"
      alt="Avis de nos sherpas!"
      rate={4}
      name={<FormattedMessage {...messages.cardTitle4} />}
    />
    <Carroussel.QuoteStudent
      description={<FormattedMessage {...messages.cardDescription5} />}
      imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-verbatim-05.jpg"
      alt="Avis de nos sherpas!"
      rate={5}
      name={<FormattedMessage {...messages.cardTitle5} />}
    />
  </Carroussel>
);

export default LandingCarroussel;
