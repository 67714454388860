import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { text, common }, breakpoints }) => ({
  rootAvantageSherpas: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    padding: `${rem(40)} ${rem(16)} ${rem(48)} ${rem(16)}`,
    backgroundColor: common.white,
    [breakpoints.up('md')]: {
      padding: `${rem(60)} ${rem(16)} ${rem(48)}`,
    },
    [breakpoints.up('lg')]: {
      padding: `${rem(80)} ${rem(16)} ${rem(48)}`,
    },
  },
  title: {
    textAlign: 'center',
  },
  subTitle: {
    color: text.secondary,
    marginTop: rem(20),
    textAlign: 'center',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: rem(56),
    margin: `${rem(36)} ${rem(16)} 0`,
    gap: rem(32),
    [breakpoints.up('md')]: {
      margin: `${rem(40)} ${rem(16)} 0`,
    },
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: `${rem(60)} ${rem(16)}`,
    },
  },
}));
