import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.visio.title',
    defaultMessage: 'Notre salle de classe en ligne',
  },
  subtitle: {
    id: 'landing.visio.subtitle',
    defaultMessage: `{isTeacher,select,
        true {S’il est possible de donner des cours à domicile sur Les Sherpas, nous proposons les meilleurs outils pour prendre des cours en ligne. <enter></enter> Donne cours à tes élèves où tu veux et quand tu veux !}
        other {S’il est possible de prendre des cours à domicile sur Les Sherpas, nous proposons les meilleurs outils pour prendre des cours en ligne. <enter></enter> Profitez de nos professeurs où vous voulez, quand vous voulez ! }
       }`,
  },
  sectionOneTitle: {
    id: 'landing.visio.section.one.title',
    defaultMessage: 'Les meilleurs outils',
  },
  sectionOneSubtitle: {
    id: 'landing.visio.section.one.subtitle',
    defaultMessage:
      'Visioconférence, partager son écran, échanger des documents, poser une question ... toutes les fonctions indispensables sont à portée de clic !',
  },
  sectionTwoTitle: {
    id: 'landing.visio.section.two.title',
    defaultMessage: `{isTeacher, select,
      true {Organise ton cours en ligne}
      other {Une bande passante optimisée}
    }`,
  },
  sectionTwoSubtitle: {
    id: 'landing.visio.section.two.subtitle',
    defaultMessage: `{isTeacher, select,
        true {En tant qu’administrateur, tu possèdes tous les droits. Tu as la main sur ta salle de classe et sur tous les participants.}
        other {Il n’est pas toujours évident d’avoir une bonne connexion : notre visioconférence optimise votre bande passante pour un échange fluide et agréable.}
    }`,
  },
  sectionThreeTitle: {
    id: 'landing.visio.section.three.title',
    defaultMessage: 'Tout au même endroit',
  },
  sectionThreeSubtitle: {
    id: 'landing.visio.section.three.subtitle',
    defaultMessage:
      'Tout est directement intégré au sein de notre plateforme : pas besoin de créer un lien, il suffit d’un clic pour rejoindre la salle de classe.',
  },
});
