import { FC, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import Breadcrumb, { BreadcrumbProps } from '@/Molecules/Breadcrumb/Breadcrumb';
import Hero from '@Landing/Systems/Hero/Hero';
import IncomeSimulator from '@Landing/Systems/IncomeSimulator/IncomeSimulator';

import useStyles from './styles';

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  backgroundImage?: string;
};

type Compound = {
  Breadcrumb: BreadcrumbProps;
};

const LandingTeacherHero: FC<PropsWithChildren<Props>> & Compound = ({
  title,
  subtitle,
  backgroundImage = 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-hero-teacher.jpg',
  children,
}) => {
  const { classes } = useStyles({ hasBreadcrumb: !!children });

  return (
    <Hero className={classes.hero} backgroundImage={backgroundImage}>
      <div className={classes.heroHeader}>
        <div className={classes.breadcrumbs}>{children}</div>
        <div className={classes.content}>
          <Typography
            variant="title2Xl"
            component="h1"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            variant="bodyXl"
            component="h2"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        </div>
        <IncomeSimulator className={classes.simulator} />
      </div>
    </Hero>
  );
};

LandingTeacherHero.Breadcrumb = Breadcrumb;

export default LandingTeacherHero;
