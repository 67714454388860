import { FC, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  imageUrl: string;
  alt: string;
};

const Advantage: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
  imageUrl,
  alt,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <Image
          src={imageUrl}
          alt={alt}
          sizes="100vw"
          fill
          loader={getImageFromCloudImage}
          className={classes.image}
        />
      </div>
      <Typography variant="title2Xs" component="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2xl" component="p" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};
export default Advantage;
