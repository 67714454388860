import { FC, PropsWithChildren, ReactElement, useState } from 'react';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

import SelectDrawer from '@/Molecules/Select/Drawer/SelectDrawer';

import useStyles from './styles';

type Props = {
  error?: boolean;
  selectors: string[] | number[];
  label?: string | ReactElement;
  value: number;
  isReadOnly?: boolean;
  onChange: (_value: number) => void;
  className?: string;
};

const Select: FC<PropsWithChildren<Props>> = ({
  error,
  selectors,
  value,
  onChange,
  label = '',
  isReadOnly = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { classes, cx } = useStyles({ error, isOpen });

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  return (
    <div className={cx(classes.select, className)}>
      {label && (
        <Typography
          variant="bodyMdBold"
          component="h5"
          className={classes.label}
        >
          {label}
        </Typography>
      )}
      <button
        onClick={() => setIsOpen(true)}
        disabled={isReadOnly}
        className={classes.displayButton}
        type="button"
      >
        {selectors[value]}
        <KeyboardArrowDownRounded
          fontSize="small"
          className={classes.arrowDown}
        />
      </button>
      {isOpen && (
        <SelectDrawer
          onClose={() => setIsOpen(false)}
          selectors={selectors}
          value={value}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default Select;
