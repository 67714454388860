import { useCallback, useState } from 'react';

type Props = {
  isOpenByDefault?: boolean;
};

export type UseOpenCloseReturnType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggleOpen: () => void;
};

const useOpenClose = ({
  isOpenByDefault = false,
}: Props): UseOpenCloseReturnType => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  return { isOpen, open, close, toggleOpen };
};

export default useOpenClose;
