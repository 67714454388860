import { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import useNavigation from '@/shared/Hooks/useNavigation';
import Features from '@Landing/Systems/Features/Features';

import messages from './messages';

import useStyles from './styles';

const HowItWorks: FC<PropsWithChildren<unknown>> = () => {
  const { pushTo, urls } = useNavigation();
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <article className={classes.howItWorks}>
      <div className={classes.titleContainer}>
        <Typography variant="titleLg" component="h2">
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography
          variant="body2xl"
          component="p"
          className={classes.subtitle}
        >
          <FormattedMessage {...messages.subtitle} />
        </Typography>
      </div>
      <Features backgroundColor="red">
        <Features.FeatureCard
          title={<FormattedMessage {...messages.firstTitle} />}
          subtitle={<FormattedMessage {...messages.firstSubtitle} />}
          buttonLabel={formatMessage(messages.firstCta)}
          onClick={() => {
            pushTo(urls.sherpaSignUp());
          }}
        />
        <Features.BackgroundImage
          url="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-works-teacher-01.png"
          alt="Étudiants qui se préparent à devenir profs particuliers"
        />
      </Features>
      <Features backgroundColor="amber" isReverse>
        <Features.FeatureCard
          title={<FormattedMessage {...messages.secondTitle} />}
          subtitle={<FormattedMessage {...messages.secondSubtitle} />}
          buttonLabel={formatMessage(messages.secondCta)}
          onClick={() => {
            pushTo(urls.sherpaSignUp());
          }}
        />
        <Features.BackgroundImage
          url="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-works-teacher-03.png"
          alt="Étudiants prêts à prendre des cours particuliers"
          className={classes.imageSection2}
        />
      </Features>
      <Features backgroundColor="blue">
        <Features.FeatureCard
          title={<FormattedMessage {...messages.thirdTitle} />}
          subtitle={<FormattedMessage {...messages.thirdSubtitle} />}
          buttonLabel={formatMessage(messages.thirdCta)}
          onClick={() => {
            pushTo(urls.sherpaSignUp());
          }}
        />
        <Features.BackgroundImage
          url="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-works-teacher-02.png"
          alt="Étudiante qui donne des cours particuliers"
        />
      </Features>
    </article>
  );
};

export default HowItWorks;
