import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import maxWidthContainer from '@/styles/maxWidth';

export default makeStyles()(({ palette, breakpoints }) => ({
  visio: {
    background: palette.neutral.dark,
    color: palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${rem(40)} ${rem(16)} ${rem(80)}`,
    [breakpoints.up('md')]: {
      padding: `${rem(40)} ${rem(20)} ${rem(80)}`,
    },
    [breakpoints.up('lg')]: {
      padding: rem(80),
    },
  },
  visioImage: {
    [breakpoints.up('md')]: {
      width: rem(704),
      height: rem(414),
    },
    [breakpoints.up('lg')]: {
      width: rem(1240),
      height: rem(730),
    },
  },
  sections: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexDirection: 'column',
    gap: rem(20),
    marginTop: rem(60),
    ...maxWidthContainer,
    [breakpoints.up('md')]: {
      gap: rem(24),
    },
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      gap: rem(32),
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  title: {
    marginBottom: rem(16),
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: rem(36),
    textAlign: 'center',
    color: palette.neutral[600],
    [breakpoints.up('md')]: {
      marginBottom: rem(40),
    },
    [breakpoints.up('lg')]: {
      marginBottom: rem(60),
    },
  },
  divider: {
    [breakpoints.up('lg')]: {
      height: rem(116),
      backgroundColor: palette.grey[800],
    },
  },
}));
