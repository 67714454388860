import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  error?: boolean;
  isOpen: boolean;
};

export default makeStyles<Props>()(
  ({ palette, border }, { error, isOpen }) => ({
    visuallyhidden: {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
    select: {
      borderRadius: '8px !important',
      backgroundColor: palette.common.white,
      position: 'relative',
      width: '100%',
    },
    title: {
      fontSize: rem(14),
      fontWeight: 700,
    },
    displayButton: {
      padding: rem(12),
      cursor: 'pointer',
      backgroundColor: palette.common.white,
      borderRadius: rem(8),
      border: error ? `1px solid ${palette.supporting.red[500]}` : border[700],
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: palette.neutral[200],
      fontWeight: 'initial',
      '&:hover': {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.primary.main}`,
        boxShadow: '0px 0px 0px 4px rgba(80, 198, 134, 0.12)',
      },
    },
    arrowDown: {
      transform: isOpen && `rotate(0.5turn)`,
      transition: `transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    label: {
      marginBottom: rem(8),
      color: palette.neutral[200],
    },
  })
);
