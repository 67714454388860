import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints, shadow }) => ({
  incomeSimulator: {
    borderRadius: rem(20),
    backgroundColor: palette.common.white,
    padding: `${rem(20)} ${rem(20)} ${rem(28)}`,
    boxShadow: shadow.high,
    [breakpoints.up('md')]: {
      padding: `${rem(32)} ${rem(32)} ${rem(36)}`,
    },
  },
  title: {
    marginBottom: rem(16),
  },
  selectorLevel: {
    marginBottom: rem(28),
  },
  selectorHour: {
    marginBottom: rem(36),
  },
  rail: {
    height: rem(8),
    borderRadius: rem(1000),
    backgroundColor: palette.neutral[800],
  },
  slider: {
    height: rem(8),
    marginBottom: rem(3),
  },
  track: {
    height: rem(8),
    borderRadius: rem(1000),
    backgroundColor: palette.primary[400],
  },
  thumb: {
    width: rem(24),
    height: rem(24),
    backgroundColor: palette.primary.main,
  },
  mark: {
    display: 'none',
  },
  grey: {
    color: palette.neutral[500],
  },
}));
