import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import maxWidthContainer from '@/styles/maxWidth';

export default makeStyles<{ hasBreadcrumb: boolean }>()(
  ({ palette, breakpoints }, { hasBreadcrumb }) => ({
    hero: {
      height: 'inherit',
    },
    heroHeader: {
      zIndex: 2,
      ...maxWidthContainer,
      width: '100%',
      margin: 'auto',
      position: 'relative',
      [breakpoints.up('lg')]: {
        marginTop: 'auto',
        marginBottom: 0,
        display: 'flex',
        alignItems: 'flex-end',
        gap: rem(140),
      },
    },
    simulator: {
      [breakpoints.up('lg')]: {
        minWidth: rem(484),
      },
    },
    title: {
      color: palette.common.white,
      marginBottom: rem(20),
    },
    subtitle: {
      color: palette.common.white,
      opacity: 0.8,
      marginBottom: rem(32),
      [breakpoints.up('md')]: {
        marginBottom: rem(52),
      },
    },
    breadcrumbs: {
      zIndex: 5,
      display: hasBreadcrumb ? 'block' : 'none',
      overflow: hasBreadcrumb && 'auto',
      paddingRight: hasBreadcrumb && rem(36),
      [breakpoints.up('md')]: {
        top: hasBreadcrumb && rem(28),
        position: 'absolute',
      },
    },
    content: {
      marginTop: rem(32),
      [breakpoints.up('md')]: {
        marginTop: rem(180),
      },
    },
  })
);
