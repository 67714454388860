import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  sectionTitle: {
    [breakpoints.up('md')]: {
      display: 'flex',
      gap: rem(6),
    },
    alignItems: 'center',
  },
  textWhite: {
    color: palette.common.white,
  },
  icon: {
    color: palette.primary[400],
  },
}));
